import React from "react"
import { graphql, useStaticQuery } from "gatsby"

//Styled components imports
import Wrapper from "./Wrapper"
import Figure from "./Figure"
import Image from "./Image"
import Figcaption from "./Figcaption"
import FigText from "./FigText"
import FigLink from "./FigLink"
import FigLinkWrapper from "./FigLinkWrapper"

const ProjectList = () => {
  const data = useStaticQuery(graphql`
    query Images {
      images: allFile(
        filter: { relativeDirectory: { eq: "projects" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          name
          id
        }
      }
    }
  `)

  const getFigcaptionData = name => {
    switch (name) {
      case "1mobilizujeme":
        return {
          description:
            "Přední český online magazín o mobilních telefonech a dalších chytrých zařízeních.",
          url: "https://mobilizujeme.cz/",
        }
      case "2archa":
        return {
          description: "Webová aplikace pro správu firemních karet.",
          url: "https://app.archa.com.au/",
        }
      case "3elisha":
        return {
          description:
            "Eliška Tomková, módní návrhářka a majitelka internetového obchodu.",
          url: "https://eliskatomkova.cz/",
        }
      case "4cme":
        return {
          description:
            "Mediální a zábavní společnost provozující přední televizní stanice.",
          url: "https://www.cme.net/",
        }
      case "5nove-regiony":
        return {
          description:
            "Online magazín s nejzajímavějšími příběhy z Mladoboleslavska, Rychnovska a Vrchlabska.",
          url: "https://www.noveregiony.cz/",
        }
      case "6mercedes":
        return {
          description: "Tiskové zprávy a novinky ze světa Mercedes-Benz.",
          url: "https://media.mercedes-benz.cz/",
        }
      case "7radka-brozova":
        return {
          description:
            "Radka Brožová, autorka dětské knížky PRO DĚTI A PRO RADOST.",
          url: "http://radkabrozova.cz/",
        }
      case "8libkov":
        return {
          description: "Oficiální stránky obce Libkov.",
          url: "https://www.libkov.cz/",
        }
      case "90cssloaders":
        return {
          description: "Jednoduché CSS animace.",
          url: "https://cssloaders.dev/",
        }
      case "910inteligentni-svet":
        return {
          description: "Moderní magazín o technologiích a životu budoucnosti.",
          url: "https://inteligentnisvet.cz/",
        }
      case "911arteman":
        return {
          description: "Česká obchodní firma s technickým zaměřením.",
          url: "https://arteman.cz/",
        }
      case "912skrz":
        return {
          description: "Slevy na pobyty v ČR i zahraničí.",
          url: "https://skrz.cz/",
        }
      case "913qr-kod-generator":
        return {
          description: "Webová aplikace pro generování QR kódu.",
          url: "https://qrkodgenerator.cz/",
        }
      case "914elektricke-vozy":
        return {
          description: "Největší český online magazín o elektromobilitě.",
          url: "https://elektrickevozy.cz/",
        }
      case "915hotel-dolina":
        return {
          description:
            "Hotel přímo v centru města Veľký Krtíš na jížním Slovensku.",
          url: "https://hoteldolina.eu/",
        }
      default:
        return { description: "Projekt", url: "https://karelkvitek.cz/" }
    }
  }

  return (
    <Wrapper>
      {data.images.nodes.map(image => {
        return (
          <Figure key={image.id}>
            <Image fluid={image.childImageSharp.fluid} alt="Ukázka z knížky" />
            <Figcaption>
              <FigText>{getFigcaptionData(image.name).description}</FigText>
              <FigLinkWrapper>
                <FigLink
                  href={getFigcaptionData(image.name).url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {image.name === "4evently" || image.name === "6airly"
                    ? "Navštivit GitHub repo"
                    : "Navštívit stránku"}
                </FigLink>
              </FigLinkWrapper>
            </Figcaption>
          </Figure>
        )
      })}
    </Wrapper>
  )
}

export default ProjectList
