import styled from "styled-components"

const Button = styled.button`
  font-family: "Jost", sans-serif;
  font-weight: 600;
  font-size: 18px;
  border: 1px solid ${props => props.theme.colors.main};
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &:hover  {
    background-color: ${props => props.theme.colors.main};
    color: #fafafa;
  }
`

export default Button
