import styled from "styled-components"
import Img from "gatsby-image"

const Image = styled(Img)`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`

export default Image
