import styled from "styled-components"

const Title = styled.h2`
  font-weight: 600;
  font-size: 28px;
  text-align: center;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 32px;
  }
`

export default Title
