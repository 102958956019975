import styled from "styled-components"

const NameText = styled.h1`
  font-weight: 600;
  font-size: 38px;

  @media (min-width: 360px) {
    font-size: 48px;
  }

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 64px;
  }
`

export default NameText
