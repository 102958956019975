import styled from "styled-components"

const DescriptionText = styled.p`
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 30px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 18px;
    max-width: 300px;
  }
`

export default DescriptionText
