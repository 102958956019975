import styled from "styled-components"

const Line = styled.div`
  width: 60px;
  height: 5px;
  background: ${props => props.theme.colors.main};
  border-radius: 2px;
  margin: 12px auto 24px;
`

export default Line
