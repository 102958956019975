import styled from "styled-components"

const FigLink = styled.a`
  font-weight: 600;
  font-size: 16px;
  border: 1px solid ${props => props.theme.colors.main};
  background-color: ${props => props.theme.colors.background2};
  color: ${props => props.theme.colors.text};
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.1s ease-in-out;

  &:hover  {
    background-color: ${props => props.theme.colors.main};
    color: #fafafa;
  }
`

export default FigLink
