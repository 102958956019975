import styled from "styled-components"

const Figure = styled.figure`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  margin: 0;

  &:hover {
    transform: scale(1.02);
  }
`

export default Figure
