import styled from "styled-components"

const CreateText = styled.p`
  font-weight: 600;
  color: ${props => props.theme.colors.complementary};
  font-size: 28px;

  @media (min-width: 360px) {
    font-size: 32px;
  }

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 42px;
  }
`

export default CreateText
