import React from "react"

//React components imports
import Layout from "../components/Layout"
import Intro from "../components/Intro"
import Projects from "../components/Projects"

const PortfolioPage = () => {
  return (
    <Layout location="portfolio">
      <Intro />
      <Projects />
    </Layout>
  )
}

export default PortfolioPage
