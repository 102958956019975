import styled from "styled-components"

const Wrapper = styled.div`
  margin-top: 32px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    margin-top: 64px;
    display: grid;

    grid-template-columns: 2fr 1fr;
    grid-column-gap: 32px;
    /* padding-left: 120px; */
  }
`

export default Wrapper
