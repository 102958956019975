import styled from "styled-components"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  margin-top: 42px;
  justify-items: center;

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export default Wrapper
