import React from "react"

//React components imports
import ProjectList from "./ProjectList"

//Styled components imports
import Wrapper from "./Wrapper"
import Title from "./Title"
import Line from "./Line"
import MainText from "./MainText"

const Projects = () => {
  return (
    <Wrapper>
      <Title>Nedávné projekty</Title>
      <Line />
      <MainText>
        Zde je ukázka několika webových projektů, na kterých jsem nedávno
        pracoval.
      </MainText>
      <ProjectList />
    </Wrapper>
  )
}

export default Projects
