import styled from "styled-components"

const HiText = styled.p`
  padding-left: 3px;
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.theme.colors.main};

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 16px;
  }
`

export default HiText
