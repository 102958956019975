import styled from "styled-components"

const Figcaption = styled.figcaption`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  padding: 15px;
  background-color: ${props => props.theme.colors.background2};
  transition: all 0.35s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover {
    opacity: 1;
  }
`

export default Figcaption
