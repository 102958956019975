import React, { useContext } from "react"
import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"
import Typist from "react-typist"

//Styled components imports
import Wrapper from "./Wrapper"
import HiText from "./HiText"
import NameText from "./NameText"
import CreateText from "./CreateText"
import DescriptionText from "./DescriptionText"
import Button from "../../shared-styled-components/Button"
import LogoAnimatedWrapper from "./LogoAnimatedWrapper"
import Media from "./Media"

import LogoAnimated from "../../images/logo-animated.svg"
import LogoAnimatedLight from "../../images/logo-animated-light.svg"

const Intro = () => {
  const themeContext = useContext(ThemeManagerContext)
  return (
    <Wrapper>
      <div>
        <HiText>Ahoj, jmenuji se</HiText>
        <NameText>Karel Kvítek.</NameText>

        <Media greaterThanOrEqual="desktop">
          <Typist
            cursor={{
              show: false,
              blink: true,
              element: "|",
              hideWhenDone: true,
              hideWhenDoneDelay: 200,
            }}
          >
            <CreateText>Vytvářím webové stránky a aplikace.</CreateText>
          </Typist>
        </Media>

        <Media lessThan="desktop">
          <CreateText>Vytvářím webové stránky a aplikace.</CreateText>
        </Media>

        <DescriptionText>
          Jsem front-end developer se sídlem v Praze a rád pro Vás vytvořím
          řešení na míru.
        </DescriptionText>

        <a href="mailto:karel.kvitek@seznam.cz" aria-label="E-mail">
          <Button>Kontaktovat</Button>
        </a>
      </div>
      <Media greaterThanOrEqual="tablet">
        <LogoAnimatedWrapper>
          {themeContext.isDark ? <LogoAnimated /> : <LogoAnimatedLight />}
        </LogoAnimatedWrapper>
      </Media>
    </Wrapper>
  )
}

export default Intro
